import React from "react"
import { Box } from "theme-ui"

import BlockContainer from "./block-container"
import HeroText from "./hero-text"

const ContentfulBlogHeroBlock = () => {
  return (
    <BlockContainer noPaddings>
      <Box pt="103px">
        <HeroText
          animationStrengthFactor={10000}
          fontFamily="Manicotti"
          fontSize={300}
          identifier="hero-text-blog"
          label="BLOG"
        />
      </Box>
    </BlockContainer>
  )
}

export default ContentfulBlogHeroBlock
