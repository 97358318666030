import React from "react"
import { graphql } from "gatsby"

import ContentfulBlogHeroBlock from "../components/contentful-blog-hero-block"
import ContentfulBlogPostsBlock from "../components/contentful-blog-posts-block"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPage = ({ pageContext, data }) => {
  const blogPage = pageContext.node
  const { contentfulHomePage } = data
  const { headerLabel } = contentfulHomePage

  return (
    <>
      <SEO
        description={blogPage.seoDescription?.seoDescription}
        title={blogPage.seoTitle}
      />
      <Layout headerLabel={headerLabel}>
        <ContentfulBlogHeroBlock />
        <ContentfulBlogPostsBlock blogPosts={blogPage.blogPosts} />
        <ContentfulFooterBlock
          address={blogPage.footer?.address?.address}
          backgroundColor={blogPage.footer?.backgroundColor}
          copyright={blogPage.footer?.copyright?.copyright}
          socialLinksTitle={blogPage.footer?.socialLinksTitle}
          blogTitle={blogPage.footer?.blogTitle}
          newsletterJoinTitle={blogPage.footer?.newsletterJoinTitle}
          facebookLink={blogPage.footer?.facebookLink}
          form={blogPage.footer?.form}
          gif={blogPage.footer?.gIf}
          gifDescription={blogPage.footer?.gifDescription}
          instagramLink={blogPage.footer?.instagramLink}
          linkedInLink={blogPage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    contentfulHomePage {
      headerLabel
    }
  }
`

export default BlogPage
