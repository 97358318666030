import _ from "lodash"
import React, { useState } from "react"
import { AspectRatio, Box, Flex, Grid, Heading, Text } from "theme-ui"

import BlockContainer from "./block-container"
import { scrollToContainer } from "./horizontal-scroller"
import { InternalLink } from "./link"
import MenuItem from "./menu-item"

const LAZY_LOADING_STEP = 9

const ContentfulBlogPostsBlock = ({
  blogPosts: referenceList1,
}) => {
  const [currentCategory, setCurrentCategory] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  let categories = []

  for (const blogPost of referenceList1) {
    for (const category of blogPost.categories || []) {
      categories.push(category?.toLowerCase())
    }
  }

  categories = _.uniq(categories)

  const blogPosts = currentCategory
    ? referenceList1?.filter((blogPost) =>
        blogPost.categories
          ?.map((category) => category?.toLowerCase())
          .includes(currentCategory),
      )
    : referenceList1

  const numberOfPages = Math.ceil(blogPosts.length / LAZY_LOADING_STEP) 
  return (
    <BlockContainer noPaddings>
      <Box>
        <Flex
          sx={{
            alignItems: ["flex-start", "flex-start", "center", "center"],
            flexDirection: ["column", "column", "row", "row"],
            mb: 5,
          }}
        >
          <Heading as="h3" variant="h3">
            Shuffle:
          </Heading>
          {categories?.map((category, index) => (
            <Box key={`category-${index}`} sx={{ ml: [0, 0, 4, 4], mt: 2 }}>
              <MenuItem
                identifier={`shuffle-${index}`}
                label={category}
                onClick={() => {
                  setCurrentCategory(category)
                  setCurrentPage(1)
                }}
              />
            </Box>
          ))}
          {currentCategory && (
            <Box sx={{ ml: [0, 0, 4, 4], mt: 2 }}>
              <MenuItem
                identifier={`shuffle-x`}
                label="x"
                onClick={() => {
                  setCurrentCategory(undefined)
                  setCurrentPage(1)
                }}
              />
            </Box>
          )}
        </Flex>
        <Grid columns={[1, 1, 2, 3]} gap={80} sx={{ pb: 6 }}>
          {blogPosts
            ?.slice(
              (currentPage - 1) * LAZY_LOADING_STEP,
              currentPage * LAZY_LOADING_STEP,
            )
            .map((blogPost, index) => {
              return (
                <InternalLink key={`blog-index-${index}-${currentPage}`} href={blogPost.slug}>
                  <Box>
                    <AspectRatio ratio={1 / 1}>
                      <img
                        alt={blogPost.title}
                        src={blogPost.picture?.resize?.src}
                        style={{
                          height: "100%",
                          objectFit: "cover",
                          width: "100%",
                        }}
                      />
                    </AspectRatio>
                    <Heading as="h3" sx={{ mt: 4 }} variant="h3">
                      {blogPost.title}
                    </Heading>
                    <Text sx={{ mt: 3 }}>{blogPost.summary?.summary}</Text>
                    <Box sx={{ mt: 3 }}>
                      <MenuItem
                        identifier={`read-more-${blogPost.slug}`}
                        label="Read more"
                      />
                    </Box>
                    {blogPost.categories?.map((category, index) => (
                      <Text
                        key={`category-alt-${index}`}
                        onClick={() => {
                          setCurrentCategory(category?.toLowerCase())
                          setCurrentPage(1)
                        }}
                        sx={{ color: "placeholder", mt: 2 }}
                        variant="copyright"
                      >
                        {category}
                      </Text>
                    ))}
                  </Box>
                </InternalLink>
              )
            })}
        </Grid>
        
        <Flex
          sx={{
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box sx={{ bg: "body", flex: 1, height: "1px", mr: 3 }} />
          <Text
            onClick={() => {
              setCurrentPage(Math.max(currentPage - 1, 1))
              scrollToContainer("#top")
            }}
            sx={{ p: 3 }}
          >
            ←
          </Text>
          {
           numberOfPages <= 4 ? (
             <>
            {_.times(numberOfPages, (page) => (
              <Text
                onClick={() => {
                  setCurrentPage(page + 1)
                  scrollToContainer("#top")
                }}
                sx={{
                  fontWeight: currentPage === page + 1 ? "bold" : "normal",
                  opacity: currentPage === page + 1 ? 1 : 0.5,
                  p: 3,
                }}
              >
                {page + 1}
              </Text>
            ))}
            </>
           ) : (
            <>
              <Text
                onClick={() => {
                  setCurrentPage(1)
                  scrollToContainer("#top")
                }}
                sx={{
                  fontWeight: currentPage === 1 ? "bold" : "normal",
                  opacity: currentPage === 1 ? 1 : 0.5,
                  p: 3,
                }}
              >
                1
              </Text>
              <Text
                sx={{
                  fontWeight: "normal",
                  opacity: 0.5,
                }}>...</Text>
              {currentPage !== 1 && currentPage !== numberOfPages && (
                <>
                  <Text
                    onClick={() => {
                      setCurrentPage(currentPage)
                      scrollToContainer("#top")
                    }}
                    sx={{
                      fontWeight: "bold",
                      opacity: 1,
                      p: 3,
                    }}
                  >
                    {currentPage}
                  </Text>
                  <Text sx={{
                  fontWeight: "normal",
                  opacity: 0.5,
                }}>...</Text>
                </>
              )}
              <Text
                onClick={() => {
                  setCurrentPage(numberOfPages)
                  scrollToContainer("#top")
                }}
                sx={{
                  fontWeight: currentPage === numberOfPages ? "bold" : "normal",
                  opacity: currentPage === numberOfPages ? 1 : 0.5,
                  p: 3,
                }}
              >
                {numberOfPages}
              </Text>
            </>
            
           )
          }

          <Text
            onClick={() => {
              setCurrentPage(
                Math.min(
                  currentPage + 1,
                  Math.ceil(blogPosts.length / LAZY_LOADING_STEP),
                ),
              )
              scrollToContainer("#top")
            }}
            sx={{ p: 3 }}
          >
            →
          </Text>
          <Box sx={{ bg: "body", flex: 1, height: "1px", ml: 3 }} />
        </Flex>

      </Box>
    </BlockContainer>
  )
}

export default ContentfulBlogPostsBlock
